@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600&display=swap");
.faq-container > div {
  padding-left: 30px;
  padding-right: 30px;
}
.faq-container .faq-header-bg {
  border-radius: 12px;
  background-image: url("../../Assests/faq-header-bg.svg");
  background-position: center top;
  background-repeat: no-repeat;
  height: 220px;
  padding: 40px;
}
.faq-container .faq-header-bg .faq-header-title {
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
  text-align: center;
  color: #111827;
  padding-bottom: 25px;
}
.faq-container .faq-header-bg .faq-header-input {
  border: 0px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #6b7280;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  box-shadow: 0px 0px 6px 0px #dddddd;
}
.faq-container .faq-header-bg p {
  padding-top: 15px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #111827;
}
.faq-container {
  margin-bottom: 3rem;
}
.faq-container .faq-qa-card {
  border: 0px solid #d2d2d2;
  border-radius: 5px;
}
.faq-container .faq-qa-card h2 {
  color: #111827 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
}
.faq-container .emailNotification h6 {
  font-size: 16px !important;
}
.faq-container .pushNotification h6 {
  font-size: 16px !important;
}
.faq-container .emailNotification p {
  color: #4b5563;
  font-size: 14px;
  font-weight: 400;
}
.faq-container .pushNotification p {
  color: #4b5563;
  font-size: 14px;
  font-weight: 400;
}
.form-switch .form-check-input {
  background-color: #4b5563 !important;
  cursor: pointer;
  height: 24px !important;
  width: 40px !important;
}
.faq-container .emailBodyContent,
.faq-container .pushBodyContent {
  display: flex;
}
.faq-container .rightContent {
  margin-left: 12px;
}
.form-switch .form-check-input:checked {
  background-color: #44a048 !important;
  border-color: #44a048 !important;
}

.faq-qa-card .faq-qa-header-container {
  display: flex;
}
.faq-qa-card .faq-qa-header-container .faq-qa-header {
  padding-right: 15px;
}
.faq-qa-card .faq-qa-header-container .faq-qa-header .faq-qa-header-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #00976d;
  padding: 14px;
}
.faq-qa-card .faq-qa-header-container .faq-qa-header h2 {
  font-family: Plus Jakarta Sans;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827 !important;
  margin-bottom: 0px;
}
.faq-qa-card .faq-qa-header-container .faq-qa-header p {
  font-family: Plus Jakarta Sans !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
}

.faq-qa-card .faq-qa-card-container .accordion-button {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}
.faq-qa-card .faq-qa-card-container .accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}
.faq-qa-card .faq-qa-card-container .accordion-body {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #374151;
}

.custom-question {
  font-size: 10px;
}
.faq-side-tabs {
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;

  color: #6b7280 !important;
  display: block;
  padding: 10px 10px;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.faq-side-tabs:hover {
  background-color: #eaffec;
  color: #00976d !important;
}
/* Apply these styles to input boxes in the modal */
label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type="text"].question-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}
textarea.message-box {
  width: 100%;
  padding: 10px; /* Adjust padding to your preference */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical; /* Optionally allow vertical resizing */
}
select.category-box {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

input[type="text"]::placeholder {
  color: #999;
}

input[type="text"]:hover,
input[type="number"]:hover,
input[type="text"]:focus,
input[type="number"]:focus {
  border-color: #00976d;
  outline: none;
}

button.secondary {
  background-color: #00976d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.secondary:hover {
  background-color: #00976d;
}

.custom-button {
  background-color: #00976d !important;
  color: #ffffff;
  padding: 5px 15px;
  border: none;
  border-radius: 10px !important ;
  cursor: pointer;
  font-weight: 500;
}
.faq-header-bg {
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
}
.search-filter-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.faq-side-tab {
  list-style-type: none;
  padding: 0;
}
.MuiTypography-h6 {
  color: #111827;
  font-family: "Plus Jakarta Sans" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
}
.faq-side-tab li a {
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280 !important;
  display: block;
  padding: 10px;
  text-decoration: none;
  border-radius: 8px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1024px) {
  .custom-button {
    display: none;
  }
}
.name-btn {
  margin-top: 85px;
}
