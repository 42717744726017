.title {
  padding-top: 20px;
  margin-top: 20px;
  color: var(--trend);
  font-family: "albert light";
  text-transform: uppercase;
  font-size: 1rem;
}
.choose {
  margin-top: 20px;
  font-family: "albert" !important;
  text-transform: uppercase;
  font-size: 2.5rem;
}
.outer-box {
  position: relative;
  background-color: white;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s;
  z-index: 0;
}

.crown {
  position: absolute;
  top: -15px;
  display: flex;
  align-items: center;
  right: -10px;
  background-color: var(--trend);
  border-radius: 20px;
  padding: 2px 10px;
}
.price {
  font-family: "albert";
  margin: 10px 0px;
  font-size: 1.5rem;
}
.premium {
  font-family: "albert light";
  color: white;
}
.btn-box {
  padding: 8px 20px;
  border-radius: 20px;
  display: inline-block;
  background-color: var(--trend);
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
}
.faCheck {
  color: var(--trend);
}

.outer-box::before {
  content: "";
  position: absolute;
  background: #a0cebc;
  right: -40px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}

.outer-box:hover::before {
  background: var(--trend);
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
.outer-box:hover .features {
  color: white !important;
}
.outer-box:hover .faCheck {
  color: white !important;
}
.outer-box:hover .title {
  color: white !important;
}
.outer-box:hover .price {
  color: white !important;
}
.outer-box:hover .btn-box {
  background-color: white !important;
}
.outer-box:hover .get-start {
  color: var(--trend) !important;
}
.features {
  text-align: start;
  margin: 10px 0px;
  padding: 0 50px;
}
.features > li {
  padding: 10px 0px;
  list-style: none;
}
.get-start {
  color: white !important;
}
.about-us {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
.btn-addNew {
  background-color: white !important;
  padding: 0px 20px !important;
  border-radius: 10px !important;
  border-color: var(--trend) !important;
  color: #000 !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
