* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --trendgreen: #001912;
  --trend: #60c99f;
}
body {
  background-color: #f8f9fa;
}
.logo {
  width: 250px;
  margin-bottom: 5rem;
}
.showing {
  margin: 0;
  font-family: "Averta", sans-serif !important;
  font-size: 13px !important;
  font-weight: 100 !important;
}
.market-place-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 1;
  gap: 20px;
  width: 240px;
  justify-content: start;
  /* position: absolute; */
  /* background-color: #f9fafb !important; */
  /* border-radius: 10px; */
  /* border: 1px solid rgb(189, 189, 189); */
}
.item-marketplace-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.item-marketplace {
  width: 40px;
  /* margin: 10px 0px; */
}
.flag-icons {
  width: 20px;
}
.active-icon {
  border: 1.5px solid white;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: green;
  display: inline-block;
}
.active-icon-red {
  border: 1.5px solid white;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: red;
  display: inline-block;
}
th {
  font-family: "Averta", sans-serif !important;
  font-size: 13px !important;
  font-weight: 100 !important;
}
td {
  font-family: "Averta", sans-serif !important;
  font-size: 13px !important;
  font-weight: 100 !important;
}
.contanier-type1 {
  width: 400px;
  margin: auto;
  padding: 30px;
  padding-bottom: 0px;
}
.maincont {
  margin-left: 70px;
}
.spinner-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.outlet {
  padding: 0px 5rem;
  margin-top: 20px;
}
/* PULSE BUBBLES */
.pulse-container {
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pulse-bubble {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ffffff;
}

.pulse-bubble-1 {
  animation: pulse 0.4s ease 0s infinite alternate;
}
.pulse-bubble-2 {
  animation: pulse 0.4s ease 0.2s infinite alternate;
}
.pulse-bubble-3 {
  animation: pulse 0.4s ease 0.4s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0.25;
    transform: scale(0.75);
  }
}
main {
  padding: 35px;
}

/* login page */
.login-log {
  text-align: left;
  padding-bottom: 10px;
}
.login-log img {
  max-width: 200px;
}
.auth-login-icon {
  border: 1px solid var(--trendgreen);
  border-radius: 4px;
  margin-top: 10px;
  margin-top: 20px;
  padding: 4px;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.auth-login-icon span {
  font-weight: 700 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: #171717;
  font-size: 16px;
}
.googleicon {
  max-width: 100%;
  text-align: center;
}
.googleicon button,
.facebookicon button {
  border: 0px !important;
  box-shadow: none !important;
  background-color: transparent;
  font-size: 14px;
}

.facebookicon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin-left: -10px;
}

.facebookicon img {
  max-width: 50px;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 14px;
  padding-top: 5px;
  color: #6b7280;
  margin: 0;
}
.forgot-password a {
  color: var(--trendgreen);
  text-decoration: none;
}

.form-control {
  border: 1px solid #e5e7eb !important;
  border-radius: 4px !important;
  height: 50px !important;
  color: #171717 !important;
  font-weight: 500;
  font-size: 16px;
}
.login-checkbox {
  margin-right: 10px;
  font-size: smaller;
}
.checkbox-label {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-size: 14px;
  color: #9ca3af;
}
.btn {
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.btn-primary {
  border-radius: 4px !important;
  background: var(--trendgreen) !important;
  height: 40px !important;

  border-color: var(--trendgreen) !important;
}
.new-create-account {
  text-align: center;
  margin-top: 16px;
}
.new-create-account p,
.new-create-account p a {
  font-size: 16px;
}
.new-create-account a {
  color: var(--trendgreen);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #9ca3af;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #e5e7eb;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}
.validation-error {
  color: crimson;
}
.separator:not(:empty)::after {
  margin-left: 0.25em;
}
.btn[disabled] {
  background-color: #e9e9e9 !important;
  color: #171717 !important;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-color: #e9e9e9 !important;
}
.cont-s {
  font-size: 12px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: center;
}
.custom-control-label {
  font-weight: 400;
  font-size: 16px;
}
.default-inner h3 {
  font-size: 24px;
}
.password-cont {
  position: relative;
}
.show-password {
  position: absolute;
  right: 10px;
  top: 15px;
  color: #999;
}

.contanier-type2 {
  width: 500px;
  margin: auto;
  padding: 25px;
  padding-bottom: 0px;
}
.center-title {
  text-align: center;
  margin-bottom: 50px;
}
.contanier-type2 h2 {
  font-size: 32px;
  font-weight: 600;
}
.contanier-type2 .sub-title {
  font-size: 14px;
  color: #393939;
}
.contanier-type2 h3 {
  font-size: 20px;
  font-weight: 600;
}
.contanier-type2 p {
  font-size: 14px;
}

.onboard-cont {
  border: 1px var(--trendgreen) solid;
  border-radius: 12px;
  padding: 20px 30px;
  margin: 10px auto;
}
.onboard-warring {
  border: 1px #ffbd06 solid;
  background-color: #fffef5;
}
.onboard-success-btn button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
.onboard-success-btn button.explore {
  border: 1px var(--trendgreen) solid;
  background-color: #ffffff !important;
  color: var(--trendgreen) !important;
}

.sidebar {
  background-color: var(--trendgreen);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 0px;
  overflow: hidden;
}
.sidebar {
  width: 70px;
}
.sidebar:hover {
  width: 260px;
}
.sidebar.open {
  width: 260px;
}
.sidebar .header-wrapper .brand-logo {
  padding: 10px 20px 15px 20px;
}
.sidebar .brand-logo .logolarge {
  display: none;
  font-family: Plus Jakarta Sans, sans-serif !important;
  color: white !important;
}

.sidebar:hover .brand-logo .logosmall,
.sidebar.open .brand-logo .logosmall {
  display: none;
}
.sidebar:hover .brand-logo .logolarge,
.sidebar.open .brand-logo .logolarge {
  display: inline-flex;
}

.menus {
  padding-left: 0px;
}
.menus.bottom {
  position: absolute;
  bottom: 0px;
}
.menu-items {
  padding: 13px 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.drop-down-page {
  border-radius: 5px !important;
  border-color: #00976d;
}
.drop-down-page:focus {
  outline: none;
}

.menu-items a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: #000000 !important;
}
.menu-items:hover,
.menu-items:focus,
.menu-items.active {
  background-color: #0000003b;
  border-radius: 8px;
}
.menu-items i {
  padding: 15px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
}
.sidebar .menu-items a span {
  font-family: Plus Jakarta Sans, sans-serif !important;
  color: #82a49b !important;
  display: none;
}
.sidebar .menu-items.active a span {
  color: #ffffff !important;
}
.sidebar:hover .menu-items a span,
.sidebar.open .menu-items a span {
  display: inline-flex;
}
.sidebar:hover .menu-items i {
  margin-right: 15px;
}
/* Sidebar.css */
.white-icon {
  width: 25px;
}

.hr-line-container {
  width: 100%;
  border-bottom: 1px solid #ffffff; /* You can adjust the color and thickness of the line here */
  margin-bottom: 20px; /* Optional: Add margin at the bottom of the line */
}

/* Submenu styles */
.sidebar .submenu-dropdown {
  display: none;
  padding-left: 0px;
}
.sidebar .dropdown-icon {
  display: none;
}
.submenu-dropdown-open {
  display: block;
}
/* .submenu-dropdown,
.submenu-dropdown {
  display: block;
} */

.sidebar:hover .dropdown-icon,
.sidebar.open .dropdown-icon {
  display: block;
}
.sidebar .submenu-dropdown .menu-items {
  border-left: 1px #000000 solid;
  border-radius: 0;
  padding: 5px 0px;
  padding-left: 30px;
}
/* .sidebar:hover .submenu-dropdown, .sidebar.open .submenu-dropdown{
  display: block;
} */
.sidebar .submenu-dropdown .menu-items {
  list-style: none;
}
.sidebar .submenu-dropdown .menu-items a span {
  font-size: 14px;
}
.sidebar .submenu-dropdown .menu-items:hover,
.sidebar .submenu-dropdown .menu-items:focus,
.sidebar .submenu-dropdown .menu-items.active {
  background-color: #f9f9f9;
  border-radius: 0 8px 8px 0;
}
.sidebar:hover .has-submenu,
.sidebar.open .has-submenu {
  /* background-image: url("../../assets/images/down-arrow.svg"); */
  background-position: right 10px top 20px;
  background-repeat: no-repeat;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  color: white;
}
.page-title {
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  color: #111827;
}
.maincont nav {
  background-color: #ffffff !important;
  height: 70px;
  color: #9ca3af;
  border: 1px #f3f4f6 solid;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  padding-left: 80px;
  z-index: 10;
}
.maincont .table-footer-right nav {
  background-color: white !important;
  height: 70px;
  color: #9ca3af;
  border: 1px #f3f4f6 solid;
  position: relative !important;
  width: 100%;
  top: 0;
  right: 0;
  padding-left: 80px;
  z-index: 1;
}
.maincont .nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-link,
.dropdown-item {
  font-family: "Averta", sans-serif;
  font-size: 16px !important;
  color: #6b7280 !important;
}
.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto !important;
}
.header-search {
  background-color: #f3f4f6;
  border-radius: 4px;
}
.header-search button,
.header-search input {
  background-color: #f3f4f6;
  border: 0px;
  height: 40px;
  border-radius: 4px;
}
.header-search button {
  padding: 0 12px;
}
.header-search input {
  width: 300px;
  color: #9ca3af !important;
  outline: none;
}
.header-search button i {
  color: #9ca3af;
}
.profile-icon {
  position: relative;
  color: #6b7280;
}
.profile-icon .profile-img {
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  /* background-color: var(--trendgreen); */
}
.profile-icon i {
  position: absolute;
  bottom: -5px;
  left: 27px;
}
.profile-icon span {
  padding-left: 10px;
}
.dropdown-toggle::after {
  content: none !important;
}

.maincont .nav-item.dropdown {
  margin: 0px !important;
}
.custom-switch-container {
  cursor: default;
}
.form-switch .form-check-input {
  width: 40px !important;
  height: 24px !important;
  cursor: pointer;
  background-color: #4b5563 !important;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;
}
.form-switch .form-check-input:checked {
  border-color: var(--trendgreen) !important;
  background-color: var(--trendgreen) !important;
}
.hr-line-container {
  margin-left: 23px;
  width: 30%;
  border-bottom: 1px solid #ffffff; /* You can adjust the color and thickness of the line here */
  margin-bottom: 10px; /* Optional: Add margin at the bottom of the line */
}
.logosmall {
  width: 30px;
}
.datastorm {
  font-family: Connary fagen criteria cf, sans-serif;
  letter-spacing: 5px;
  color: black;
}

.table {
  border: 1px solid #efefef;
}

.table thead th {
  border: none;
  padding: 10px;
  background-color: white !important;
  color: black;
}
.table tbody td {
  border: none;
  padding: 20px;
  color: black;
}

/* .table tbody tr {
  margin-bottom: 10px;
  border-bottom: 4px solid #f8f9fd;
} */
.logosmalls {
  margin-left: 50px;
  width: 30px;
}
.buttonedit .edit-button {
  margin-right: 5px; /* Adjust the value to set the desired gap */
}
/* Add this CSS to your stylesheets */

/* Chart container styles */
.mixed-chart {
  width: 600px;
  height: 450px; /* Set the height of the chart */
  padding: 20px; /* Add padding inside the chart container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-left: -300px;
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .mixed-chart {
    height: 200px; /* Adjust the height for smaller screens if needed */
  }
}
/* Add this CSS to your stylesheets */

/* Chart container styles */
.gm {
  /* Set the height of the chart */
  padding: 10px; /* Add padding inside the chart container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .gm {
    height: 200px; /* Adjust the height for smaller screens if needed */
  }
}
/* YourCustomStyles.css */
.focused {
  position: absolute;
  pointer-events: none;
  top: 0;
  transition: 0.2s ease all;
  transform: translateY(-50%);
  font-size: 14px;
  color: #000; /* Change the color as needed */
}

.focused-input {
  border: 0;
  border-bottom: 1px solid #000; /* Change the color as needed */
  outline: 0;
  transition: border-color 0.2s;
}

.menu-items img {
  margin-right: 10px; /* Adjust the margin-right value to create the desired gap between the icon and text */
}
.tag-heading {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}
/* ////////// */
.active > .page-link,
.page-link.active {
  font-family: "albert light", sans-serif;
  font-size: small !important;
  z-index: -1 !important;
  color: var(--trendcolor) !important;
  border-radius: 0.5rem !important;
  background-color: #00976d !important;
  border-color: white !important;
  margin: 0px 2px !important;
}
.page-link {
  font-family: "albert light", sans-serif;
  font-size: small !important;
  position: relative;
  margin: 0px 2px !important;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: black !important;
  text-decoration: none;
  background-color: #f5f5f5 !important;
  border-radius: 0.5rem !important;
  border: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
a {
  color: #00976d !important;
  text-decoration: none !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-size: 14px !important;
}
.pagination {
  --bs-pagination-focus-box-shadow: none !important;
  padding: 10px !important;
}
.loading {
  padding: 0;
  margin: 0;
  display: flex;
  height: 550px;
  justify-content: center;
  align-items: center;
}
.loader-demo-box {
  border-radius: 0.25rem !important;
}

.loader-demo-box {
  width: 100%;
  height: 200px;
}

.jumping-dots-loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

.jumping-dots-loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #00976d;
  margin: 35px 5px;
}

.jumping-dots-loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
}

.jumping-dots-loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite;
}

.jumping-dots-loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
}
@keyframes bounce {
  0%,
  75%,
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
.input-search {
  margin-top: 10px;
  height: 58px;
  padding-left: 20px;
  width: 50%;
  border-radius: 5px;
  border: none !important;
  box-shadow: none !important;
}
.search-btn {
  position: absolute;
  top: 18px;
  right: 10px;
  height: 40px;
  border: none !important;
  background-color: #00976d !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-size: medium !important;
  font-weight: 100 !important;
  padding: 0px 15px 0px 5px !important;
  border-radius: 0.5rem !important;
}
.input-search:focus {
  outline: none;
}
.search-bar {
  display: inline-flex;
  justify-content: end;
  align-items: flex-end;
  position: relative;
  flex-grow: 1 !important;
}
.active-btn {
  text-align: center;
  border: none !important;
  background-color: #00976d !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-size: medium !important;
  font-weight: 100 !important;
  border-radius: 0.5rem !important;
}
.form-switch .form-check-input {
  background-color: #4b5563 !important;
  cursor: pointer;
  height: 24px !important;
  width: 40px !important;
  outline: none !important;
}

.form-switch .form-check-input:checked {
  background-color: #44a048 !important;
  border-color: #44a048 !important;
  outline: none !important;
}

.form-switch .form-check-input:focus {
  outline: none !important;
}
